import { render, staticRenderFns } from "./GpStar.vue?vue&type=template&id=7c390ed2&scoped=true"
import script from "./GpStar.vue?vue&type=script&lang=js"
export * from "./GpStar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c390ed2",
  null
  
)

export default component.exports